import React, { useState } from 'react';
import {
  Modal, Button, Form, Segment,
} from 'semantic-ui-react';
import PutItemByType from '../../../axios/PutItemByType';
import CreateEmptyObjectFromJoiSchema from '../../../util/item';
import AlertModal from './AlertModal';
import DynamicFormField from './DynamicFormField';

export default ({ schema, endpoint, propagate }) => {
  // For this we want to remove mongo keys because it's a new item
  const [item, setItem] = useState(CreateEmptyObjectFromJoiSchema(schema, true));
  const [alert, setAlert] = useState(null);

  const save = async () => {
    try {
      await PutItemByType(endpoint, item);
      propagate();
    } catch (ex) {
      setAlert(ex.response.data);
    }
  };

  return (
    <div>
      <Modal size="tiny" dimmer="blurring" open>
        {alert && (
        <AlertModal
          header="An Error Occured"
          msg={alert}
          propagate={() => setAlert(null)}
        />
        )}

        <Modal.Header>Create New Item</Modal.Header>
        <Modal.Content>
          <Segment>
            <Form onSubmit={() => {}}>
              {Object.entries(item)
                .filter(([k]) => !['_id', '__v'].includes(k))
                .map(([k, v]) => (
                  <DynamicFormField
                    key={k}
                    type={schema[k].type}
                    label={k}
                    value={v}
                    propagate={(val) => {
                      setItem({
                        ...item,
                        [k]: val,
                      });
                    }}
                  />
                ))}
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={propagate} />
          <Button content="Save" onClick={save} positive />
        </Modal.Actions>
      </Modal>
    </div>
  );
};
