import axios from 'axios';

/**
 * @param {string} endpoint
 * @param {{_id: string, [key: string]: any}} body
 * @returns {Promise<{
 *  _id: string,
 *  __v: number,
 *  name: string,
 *  price: number,
 *  url: string,
 *  img: string,
 *  disabled: boolean,
 *  [key: string]: any
 * }>} A Result object
 */
export default async (endpoint, { _id }) => (await axios.delete(`/api/v1/${endpoint}/${_id}`)).data;
