import axios from 'axios';

/**
 * @param {string} endpoint
 * @returns {Promise<{
 *  [key: string]: {
 *    type: string;
 *    flags: {
 *      presence: string;
 *      error: Record<any, any>
 *    }
 *  }
 * }>}
 */
export default async (endpoint) => (await axios.get(`/api/v1/schema/${endpoint}`)).data.keys;
