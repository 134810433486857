import React from 'react';
import setAuthToken from '../../axios/AuthToken';
import store from '../../redux/store';
import Login from './Login';

export default () => {
  localStorage.removeItem('auth-token');
  setAuthToken(null);
  store.dispatch({ type: 'CLEAR_USER' });

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>You Have Been Logged Out!</h2>
      <Login />
    </>
  );
};
