import axios from 'axios';

/**
 * @param {string} token The JWT token to set for axios headers
 * @returns {null}
 */
export default (token) => {
  if (token) {
    axios.defaults.headers.common['auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['auth-token'];
  }
};
