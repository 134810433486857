import React, { useLayoutEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

const CLASS_NAME_ROOT = 'item';

export default ({
  to, name, icon, right,
}) => {
  /* The below semi-janky code makes sure that the menubar works nicely on mobile */
  const [isStacked, setIsStacked] = useState(document.getElementById('root').clientWidth < 768);
  const className = `${CLASS_NAME_ROOT} ${right ? 'right' : ''} ${!isStacked && !name && icon ? 'icon' : ''}`;
  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setIsStacked(document.getElementById('root').clientWidth < 768);
    });
  }, []);

  return (
    <NavLink
      exact
      className={className}
      activeClassName="active"
      to={to}
      // onClick={(evt) => {
      //   if (to.includes('http')) {
      //     evt.stopPropagation();
      //     window.open(to, '_blank');
      //   }
      // }}
    >
      {!!icon && (<Icon name={icon} />) }
      {!!icon && !!name && ' '}
      {!!name && name}
    </NavLink>
  );
};
