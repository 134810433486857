import React, { useEffect, useState } from 'react';
import {
  Button, Icon, Tab, Table,
} from 'semantic-ui-react';
import PostItemByType from '../../../axios/PostItemByType';
import GetSchemaByType from '../../../axios/GetSchemaByType';
import Loading from '../Loading';
import EditItemModal from './EditItemModal';
import NewItemModal from './NewItemModal';

const fmt = (obj) => {
  if (typeof (obj) === 'boolean') {
    return obj ? 'Yes' : 'No';
  }
  return obj;
};

export default ({ endpoint }) => {
  const [data, setData] = useState(null);
  const [schema, setSchema] = useState(null);
  const [editing, setEditing] = useState(null);
  const [creating, setCreating] = useState(false);
  const columns = schema && Object
    .keys(schema)
    .filter((x) => !['_id', '__v', 'review'].includes(x));

  useEffect(() => {
    (async () => {
      const d = await PostItemByType(endpoint);
      const s = (await GetSchemaByType(endpoint));
      setData(d);
      setSchema(s);
    })();
  }, [setData, setSchema, endpoint]);

  if (!data || !schema) {
    return (<Loading />);
  }

  return (
    <div>
      {!!editing && (
        <EditItemModal
          schema={schema}
          endpoint={endpoint}
          editItem={editing}
          propagate={async () => {
            const d = await PostItemByType(endpoint);
            setData(d);
            setEditing(false);
          }}
        />
      )}

      {
        !!creating && (
          <NewItemModal
            schema={schema}
            endpoint={endpoint}
            propagate={async () => {
              const d = await PostItemByType(endpoint);
              setData(d);
              setCreating(false);
            }}
          />
        )
      }

      <Button
        size="large"
        style={{
          position: 'fixed', bottom: '1rem', left: '1rem', zIndex: '999999',
        }}
        onClick={() => setCreating(true)}
        color="teal"
        circular
        icon
      >
        <Icon name="plus" />
      </Button>

      <Tab.Pane style={{ maxWidth: '1024px' }}>
        <Table fixed singleLine celled color="teal">
          <Table.Header>
            <Table.Row>
              {columns
                .map((nm) => (
                  <Table.HeaderCell key={nm}>{nm}</Table.HeaderCell>
                ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.match.map((row) => (
              <Table.Row
                key={JSON.stringify(row)}
                onClick={() => setEditing(row)}
              >
                {columns.map((col) => (
                  <Table.Cell key={JSON.stringify(row[col])}>
                    {fmt(row[col])}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Tab.Pane>
    </div>
  );
};
