import React, { useEffect, useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import GetCategories from '../../axios/GetCategories';
import Loading from '../modules/Loading';
import ManagementTab from '../modules/Admin/ManagementTab';

export const ManagementComponent = ({ i18n }) => {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    (async () => {
      const c = await GetCategories();
      setCategories(c);
    })();
  }, []);

  if (!categories) {
    return (<Loading />);
  }

  const panes = categories.map((cat) => ({
    menuItem: i18n.categories[cat],
    render: () => (
      <ManagementTab endpoint={cat} />
    ),
  }));

  return (
    <Tab
      style={{ maxWidth: '1024px', margin: 'auto' }}
      panes={panes}
    />
  );
};

const mapStateToProps = ({ i18n }) => ({
  i18n,
});

export default connect(mapStateToProps)(ManagementComponent);
