import axios from 'axios';

/**
 * @param {string} endpoint
 * @param {Record<string, any>} body
 * @returns {Promise<{
 *  _id: string,
 *  __v: number,
 *  name: string,
 *  price: number,
 *  url: string,
 *  img: string,
 *  disabled: boolean,
 *  [key: string]: any
 * }[]>}
 */
export default async (endpoint, body) => (await axios.post(`/api/v1/${endpoint}s`, body)).data;
