import React from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'semantic-ui-react';
import LoadI18n from '../../../axios/GetI18n';
import LoadLangs from '../../../axios/GetLangs';
import Loading from '../Loading';
import NavItem from './NavItem';

const NavbarComponent = ({ languages, i18n, isAuthenticated }) => {
  const setLanguage = (newLanguage) => {
    window.localStorage.setItem('language', newLanguage);
    LoadI18n();
  };

  if (!languages) {
    LoadLangs();
    return (<Loading />);
  }

  if (!i18n) {
    LoadI18n();
    return (<Loading />);
  }

  return (
    <Menu stackable inverted color="teal">
      <NavItem name={i18n.search} icon="headphones" to="/" />
      <NavItem name={i18n.forums} icon="chat" to="/forums" />
      { /* Language chooser */ }
      <Dropdown item icon="language">
        <Dropdown.Menu>
          {languages.map(({ flag, name, code }) => (
            <Dropdown.Item
              text={`${flag} ${name}`}
              key={code}
              onClick={() => setLanguage(code)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {isAuthenticated && (
        <>
          <NavItem icon="edit" to="/manage" />
          <NavItem icon="chart bar" to="/dashboard" />
          <NavItem right icon="sign out" to="/logout" />
        </>
      )}
      {!isAuthenticated && (
        <NavItem right icon="sign in" to="/login" />
      )}
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  languages: state.langs,
  i18n: state.i18n,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(NavbarComponent);
