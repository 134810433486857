import React from 'react';
import { Form } from 'semantic-ui-react';

export default ({
  type, label, value, propagate,
}) => {
  switch (type) {
    case 'boolean':
      return (
        <Form.Checkbox
          label={label}
          checked={value}
          onChange={(_, { checked }) => propagate(checked)}
          disabled={label.startsWith('_')}
        />
      );
    case 'number':
      return (
        <Form.Input
          label={label}
          type="number"
          value={parseInt(value, 10) || 0}
          onChange={(_, { value: v }) => propagate(parseInt(v, 10) || 0)}
          disabled={label.startsWith('_')}
        />
      );
    default:
      return (
        <Form.Input
          label={label}
          type="text"
          value={value}
          onChange={(_, { value: v }) => propagate(v)}
          disabled={label.startsWith('_')}
        />
      );
  }
};
