/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import store from '../redux/store';
import setAuthToken from './AuthToken';

/**
 * Logs the current user in by using username and password
 *  Returns nothing because is sets redux state.
 * @param {{ username: string, password: string }} data
 * @returns {Promise<null>}
 */
export default async (data) => {
  const { data: token } = await axios.post('/api/v1/auth/login', data);
  /**
   * @type {{
   *  _doc: {
    *  _id: string;
    *  username: string;
    *  expiresAt: string;
   *  }
   * }}
  */
  const user = jwtDecode(token);
  window.localStorage.setItem('auth-token', token);
  store.dispatch({ type: 'SET_USER', user: user._doc });
  setAuthToken(token);
};
