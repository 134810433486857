import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default ({
  header, msg, onConfirm, onDeny,
}) => (
  <Modal
    open
    size="small"
    closeOnDimmerClick
  >
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content style={{ color: '#252525' }}>
      {msg}
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancel" onClick={onDeny} />
      <Button content="Confirm" onClick={onConfirm} negative />
    </Modal.Actions>
  </Modal>
);
