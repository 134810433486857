import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, Segment, Header, Icon,
} from 'semantic-ui-react';
import postLogin from '../../axios/PostLogin';

const LoginComponent = ({ isAuthenticated }) => {
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    if (isAuthenticated) {
      window.location.pathname = '/';
    }
  }, [isAuthenticated]);

  const submit = (evt) => {
    evt.preventDefault();
    postLogin(state).catch((ex) => {
      setError(`${ex.response.data}`);
    });
  };

  return (
    <div>
      <div className={error ? 'blur' : 'hidden'}>
        <div className="v-center">
          <Segment style={{ border: '1px solid #db2828', transition: '200ms ease-in-out' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Icon name="close" className="errCls" onClick={() => setError(null)} />
              <Header style={{ margin: '0' }}>An Error Occured During Login</Header>
            </div>
            <h4 style={{ color: '#252525', textAlign: 'center' }}>{error}</h4>
          </Segment>
        </div>
      </div>

      <Header
        style={{ textAlign: 'center' }}
        inverted
        size="medium"
      >
        Admin Login
      </Header>
      <Segment>
        <Form onSubmit={submit}>
          <Form.Input
            label="Username"
            type="text"
            value={state.username}
            onChange={(_, { value }) => setState({
              ...state,
              username: value,
            })}
          />
          <Form.Input
            label="Password"
            type="password"
            value={state.password}
            onChange={(_, { value }) => setState({
              ...state,
              password: value,
            })}
          />
          <Form.Button color="teal">
            <Icon name="sign in" />
            {' '}
            Log In
          </Form.Button>
        </Form>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(LoginComponent);
