import React, { useState } from 'react';
import {
  Button, Form, Modal, Segment,
} from 'semantic-ui-react';
import DeleteItemByType from '../../../axios/DeleteItemByType';
import PatchItemByType from '../../../axios/PatchItemByType';
import CreateEmptyObjectFromJoiSchema from '../../../util/item';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import DynamicFormField from './DynamicFormField';

export default ({
  schema, endpoint, editItem, propagate,
}) => {
  // For this we want to keep mongo keys because we need _id to update with
  const schemaAsObject = CreateEmptyObjectFromJoiSchema(schema, false);
  const [editing, setEditing] = useState({ ...schemaAsObject, ...editItem });
  const [alert, setAlert] = useState(null);
  const [deleting, setDeleting] = useState(null);

  const saveEdits = async () => {
    if (editing) {
      try {
        await PatchItemByType(endpoint, editing);
        propagate();
      } catch (ex) {
        setAlert(ex.response.data);
      }
    } else {
      setAlert('No editing item could be found..');
    }
  };

  const deleteItem = async () => {
    if (deleting) {
      try {
        await DeleteItemByType(endpoint, deleting);
        propagate();
      } catch (ex) {
        setAlert(ex.response.data);
      }
    } else {
      setAlert('No editing item could be found..');
    }
  };

  return (
    <div>
      <Modal
        size="tiny"
        dimmer="blurring"
        open
      >
        {/* Nested modal for alerts. */}
        {alert && (
          <AlertModal
            header="An Error Occured"
            msg={alert}
            propagate={() => setAlert(null)}
          />
        )}

        {/* Nested modal for deletion validation */}
        {deleting && (
          <ConfirmModal
            header={`Delete ${deleting.name}?`}
            msg={`Are you sure you want to delete ${deleting.name}? This cannot be undone.`}
            onConfirm={deleteItem}
            onDeny={() => { setDeleting(null); }}
          />
        )}

        <Modal.Header>{`Editing ${editing && editing.name}`}</Modal.Header>
        <Modal.Content>
          <Segment>
            <Form onSubmit={() => {}}>
              {editing && Object.entries(editing)
                .filter(([k]) => !['_id', '__v'].includes(k))
                .map(([k, v]) => (
                  <DynamicFormField
                    key={k}
                    type={schema[k].type}
                    label={k}
                    value={v}
                    propagate={(val) => {
                      setEditing({
                        ...editing,
                        [k]: val,
                      });
                    }}
                  />
                ))}
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={propagate} />
          <Button content="Delete" onClick={() => setDeleting(editing)} negative />
          <Button content="Save" onClick={saveEdits} positive />
        </Modal.Actions>
      </Modal>
    </div>
  );
};
