import React from 'react';
import {
  Dimmer, Loader, Segment,
} from 'semantic-ui-react';

export default () => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
  }}
  >
    <Segment className="custom-loader">
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    </Segment>
  </div>
);
