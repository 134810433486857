import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Header, Image, Label, Popup, Segment,
} from 'semantic-ui-react';
import GetCategories from '../../axios/GetCategories';
import PostItemByType from '../../axios/PostItemByType';
import GetQuestionChoiceMap from '../../util/questions';
import ItemGrid from '../modules/ItemGrid';
import Loading from '../modules/Loading';
import QuestionSet from '../modules/QuestionSet';

const Home = ({ i18n }) => {
  const [state, setState] = useState({
    categories: null, // ARRAY
    category: null,
    results: null,
    questions: null,
  });

  const updateResults = async (endpoint, body) => {
    const newResults = await PostItemByType(endpoint, body);
    setState({ ...state, results: newResults });
    return newResults;
  };

  useEffect(() => {
    (async () => {
      const categories = await GetCategories();
      const results = await PostItemByType(categories[0]);
      setState({
        ...state,
        category: categories[0],
        categories,
        results,
        questions: GetQuestionChoiceMap(results),
      });
    })();
  }, []);

  if (!(state.categories && state.results)) {
    return (<Loading />);
  }

  const changeCategory = async (catIdx) => {
    const newCategory = state.categories[catIdx];
    const newResults = await updateResults(newCategory);
    setState({
      ...state,
      category: newCategory,
      results: newResults,
      questions: GetQuestionChoiceMap(newResults),
    });
  };

  return (
    <div>
      {/* Fixed button for HFG overlay */}
      <Popup
        content="ZeosPantera & Z Reviews is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com"
        trigger={(
          <Button
            style={{
              position: 'fixed', bottom: '1rem', left: '1rem', zIndex: '999999',
            }}
            icon="exclamation"
            circular
            color="teal"
          />
        )}
      />

      <Segment color="teal">
        <Image src="/img/banner.png" />
        <Header className="horizontal divider">
          <Label horizontal color="teal" size="medium">
            {i18n.lookingFor}
          </Label>
        </Header>

        <Button.Group fluid vertical>
          {state.categories.map((category, idx) => (
            <Button
              className={state.category === category ? 'ui teal button' : 'ui button'}
              onClick={() => changeCategory(idx)}
              key={category}
            >
              {i18n.categories[category]}
            </Button>
          ))}
        </Button.Group>

        {state.categories.map((category) => {
          if (state.category === category) {
            return (
              <QuestionSet
                key={JSON.stringify(state.questions)}
                data={state.questions}
                onChange={(newBody) => {
                  updateResults(category, newBody);
                }}
              />
            );
          }
          return (<div key={category} />);
        })}
      </Segment>

      {/* Matches!! */}

      <div style={{ marginTop: '1rem' }} />
      <Header className="horizontal divider">
        <Label horizontal color="teal" size="medium">
          {i18n.results}
        </Label>
      </Header>
      <div style={{ marginTop: '1rem' }} />
      {!!state.results.match.length && (
        <ItemGrid data={state.results.match} i18n={i18n} />
      )}

      {/* Overpriced matches */}
      {!!state.results.above.length && (
        <div style={{ marginTop: '1rem' }}>
          <Header className="horizontal divider">
            <Label horizontal color="grey" size="medium">
              {i18n.abovePrice}
            </Label>
          </Header>
          <div style={{ marginTop: '1rem' }} />
          <ItemGrid data={state.results.above} i18n={i18n} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ i18n }) => ({
  i18n,
});

export default connect(mapStateToProps)(Home);
