/**
 * Turns a Schema (which describes an object's keys and types of values)
 *   into an empty base object to build onto
 *
 * @param {[key: string]: {
 *  type: string;
 *  flags: {
 *    presence: string;
 *    error: Record<any, any>
 *  }
 * }} schema
 *
 * @param {boolean} removeMongoKey
 *
 * @return {{
 *  name: string,
 *  price: number,
 *  url: string,
 *  img: string,
 *  disabled: boolean,
 *  [key: string]: any
 * }}
 */

export default (schema, removeMongoKeys) => Object
  .entries(schema)
  .filter(([k]) => !removeMongoKeys || !['_id', '__v'].includes(k))
  .reduce((acc, [k, { type }]) => {
    let val = '';
    switch (type) {
      case 'number':
        val = 0;
        break;
      case 'boolean':
        val = false;
        break;
      default:
        break;
    }
    return {
      ...acc,
      [k]: val,
    };
  }, {});
