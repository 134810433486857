import axios from 'axios';
import store from '../redux/store';

/**
 * Loads the server-side translations to redux state
 * @return {Promise<null>}
 */
export default async () => {
  const currentLang = window.localStorage.getItem('language') || 'en';
  const { data } = await axios.get(`/api/v1/i18n/${currentLang}`);
  store.dispatch({ type: 'SET_I18N', data });
};
