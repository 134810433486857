import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import SliderView from 'semantic-ui-react-slider';
import Question from './Question';

const QuestionSet = ({ data, onChange, i18n }) => {
  const [body, setBody] = useState({});
  const [shown, setShown] = useState(0);

  const update = async (key, val) => {
    const newBody = { ...body, [key]: val };
    if (Array.isArray(val) && val.length === 0) {
      delete newBody[key];
    }
    setBody(newBody);
    onChange(newBody);
  };

  const sliderChanged = useCallback((min, maxRaw) => {
    const max = maxRaw === 2000 ? 10000 : maxRaw;
    const newBody = {
      ...body,
      price: {
        min,
        max,
      },
    };
    setBody(newBody);
    onChange(newBody);
  }, [body, setBody, onChange]);

  const questions = Object.keys(data);

  return (
    <div>
      <div style={{
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <Label color="teal">
          {i18n.price}
        </Label>
      </div>

      <SliderView
        onSliderValuesChange={sliderChanged}
        sliderMinValue={0}
        sliderMaxValue={2000}
        className="price-slider"
      />

      { questions.map((question, idx) => {
        const choices = data[question];
        return (shown >= idx) ? (
          <Question
            name={`${i18n[question]}?`}
            choices={choices}
            multiple={choices.length > 2}
            key={JSON.stringify(choices)}
            onChange={(c) => update(question, c)}
            showSkip={(idx + 1) < questions.length}
            onSkip={() => setShown(Math.max(idx + 1, shown))}
          />
        ) : (<div key={JSON.stringify(choices)} />);
      })}
    </div>
  );
};

const mapStateToProps = ({ i18n }) => ({
  i18n,
});

export default connect(mapStateToProps)(QuestionSet);
