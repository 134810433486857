import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from '../view/Login';
import store from '../../redux/store';
import Loading from './Loading';

export default ({ children, ...rest }) => {
  const state = store.getState();

  if (state.isAuthenticated === undefined) {
    return (<Loading />);
  }

  return (state.isAuthenticated && !!state.user)
    ? (<Route {...rest} />)
    : (<Route {...rest} component={LoginPage} />);
};
