import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Segment, Header } from 'semantic-ui-react';
import GetReferrers from '../../axios/GetReferrers';
import Loading from '../modules/Loading';

export default () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const d = await GetReferrers();
      setData(d);
    })();
  }, []);

  if (!data) {
    return (<Loading />);
  }

  return (
    <Segment size="large">
      <Header size="large" className="center aligned">Websites that Lead to Here</Header>
      <Doughnut data={data} />
    </Segment>
  );
};
