import axios from 'axios';
import store from '../redux/store';

/**
 * Loads the list of possible languages to the redux state
 * @returns {Promise<null>}
 */
export default async () => {
  const { data } = await axios.get('/api/v1/i18n/');
  store.dispatch({ type: 'SET_LANGS', data });
};
