/**
 * @param {{
 *  match: Record<string, any>[]
 *  above: Record<string, any>[]
 * }} results
 * @return {Record<string, any[]>}
 */
export default (results) => {
  const ret = {};
  const data = [...results.match, ...results.above];

  if (!data.length) {
    return {};
  }

  const keys = Object.keys(
    data[0],
  ).filter((x) => !['_id', '__v', 'name',
    'price', 'url', 'img', 'review'].includes(x));

  keys.forEach((key) => {
    const choices = [
      ...new Set(data.map((x) => x[key])),
    ];

    if (choices.length === 2) {
      ret[key] = choices.sort().reverse();
    } else {
      ret[key] = choices.sort();
    }
  });

  return ret;
};
