import React from 'react';
import { Modal } from 'semantic-ui-react';

export default ({ header, msg, propagate }) => (
  <Modal
    open
    size="small"
    closeOnDimmerClick
    onClose={propagate}
  >
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content style={{ color: '#252525' }}>
      {JSON.stringify(msg)}
    </Modal.Content>
  </Modal>
);
