import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoadAuth from './auth/load';
import LoadI18n from './axios/GetI18n';
import RequiresAuth from './components/modules/Auth';
import NavBar from './components/modules/Nav/NavBar';
import Dashboard from './components/view/Dashboard';
import Home from './components/view/Home';
import Login from './components/view/Login';
import Logout from './components/view/Logout';
import Manage from './components/view/Manage';
import store from './redux/store';
import ForumLink from './components/modules/Nav/RouterUrlComponent';

export default () => {
  LoadAuth();
  LoadI18n();

  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <NavBar />
        <div style={{ paddingTop: `${3.5}em` }} />
        <Route exact path="/" component={Home} />
        <RequiresAuth exact path="/dashboard" component={Dashboard} />
        <RequiresAuth exact path="/manage" component={Manage} />
        <Route exact path="/forums" component={() => ForumLink('https://forum.hifiguides.com')} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
      </Router>
    </Provider>,
    document.getElementById('root'),
  );
};
