/* eslint-disable no-underscore-dangle */
import jwtDecode from 'jwt-decode';
import setAuthToken from '../axios/AuthToken';
import store from '../redux/store';

export default () => {
  const token = window.localStorage.getItem('auth-token');
  if (token) {
    /**
   * @type {{
   *  _doc: {
       *  _id: string;
       *  username: string;
       *  expiresAt: string;
      *  }
      * }}
     */
    const user = jwtDecode(token);
    store.dispatch({ type: 'SET_USER', user: user._doc });
    setAuthToken(token);
  }
};
