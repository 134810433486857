/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Button, Label, Icon } from 'semantic-ui-react';

export default ({
  name, choices, onChange, showSkip, onSkip, multiple = false,
}) => {
  const [selected, setSelected] = useState([]);

  const onClick = (choice) => {
    onSkip();
    if (multiple) {
      const newSelection = selected.includes(choice)
        ? selected.filter((x) => x !== choice) : [...selected, choice];
      setSelected(newSelection);
      onChange(newSelection);
    } else {
      setSelected([choice]);
      onChange(choice);
    }
  };

  return (
    <div className="question">
      <Label color="teal" style={{ marginBottom: '.5rem' }}>
        {name}
      </Label>

      <div />

      <Button.Group
        vertical={choices.length > 2}
        fluid
      >
        {choices.map((choice) => (
          <Button
            key={`${name}-${choice}`}
            className={selected.includes(choice) ? 'ui teal button' : 'ui button'}
            onClick={() => onClick(choice)}
          >
            {typeof (choice) === 'boolean'
              ? (choice ? 'Yes' : 'No')
              : choice}
          </Button>
        ))}
        {showSkip && (
          <Button
            icon
            style={{
              maxWidth: `${choices.length <= 2 ? '3rem' : ''}`,
              textAlign: 'center',
            }}
            onClick={onSkip}
          >
            <Icon name="angle double down" />
          </Button>
        )}
      </Button.Group>
    </div>
  );
};
