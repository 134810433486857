/**
 * This is meant to go into the component={} block for a ReactRouter Route
 * @param {string} url
 * @returns {null}
 */
export default (url) => {
  window.history.back();
  window.open(url, '_blank');
  return null;
};
