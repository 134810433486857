import React from 'react';
import {
  Button, ButtonGroup, Card, Icon, Image,
} from 'semantic-ui-react';

/**
 * @param {string} url
 * @return {string}
 */
const getSiteName = (url) => {
  try {
    const { hostname } = new URL(url);
    const parts = hostname.replace(/www./g, '').split('.');
    const domain = parts[parts.length - 2];
    return domain.substring(0, 1).toUpperCase() + domain.substring(1);
  } catch (ex) {
    return '';
  }
};

export default ({ data, i18n }) => (
  <Card.Group centered>
    {data.map(({
      name, img, url, review,
    }) => {
      const isAmazon = (url.includes('amzn') || url.includes('amazon'));
      const siteName = getSiteName(url);
      return (
        <Card color="teal" key={name}>
          <Card.Content>
            <Image
              src={img}
              style={{
                objectFit: 'cover',
                minWidth: '128px',
                width: '192px',
                height: '192px',
                margin: '1rem auto',
                overflow: 'hidden',
                display: 'block',
              }}
            />
            <Card.Header>
              <p style={{ textAlign: 'center' }}>
                {name}
              </p>
            </Card.Header>

          </Card.Content>
          <Card.Content extra>
            <ButtonGroup fluid>
              {isAmazon ? (
                <Button
                  icon
                  className="amazon"
                  onClick={() => window.open(url, '_blank')}
                >
                  <Icon name="amazon" />
                  {' '}
                  {i18n.buyFrom.replace('{$1}', 'Amazon')}
                </Button>
              ) : (
                <Button
                  icon
                  onClick={() => window.open(url, '_blank')}
                >
                  <Icon name="cart" />
                  {' '}
                  {i18n.buyFrom.replace('{$1}', siteName)}
                </Button>
              )}

              {!!review && (
                <Button
                  icon
                  onClick={() => window.open(review, '_blank')}
                >
                  <Icon name="youtube" />
                  {' '}
                  {i18n.review}
                </Button>
              )}
            </ButtonGroup>
          </Card.Content>
        </Card>
      );
    })}
  </Card.Group>
);
