import axios from 'axios';

/**
 * @returns {Promise<{
 *  referrer: string,
 *  count: number,
 *  updatedDtm: number,
 * }[]>}
 */
export default async () => (await axios.get('/api/v1/referrers')).data;
