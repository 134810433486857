/* eslint-disable no-underscore-dangle */
import axios from 'axios';

/**
 * @param {string} endpoint
 * @param {Record<string, any>} body
 */
export default async (endpoint, body) => {
  const { _id } = body;
  return (await axios.patch(`/api/v1/${endpoint}/${_id}`, body)).data;
};
